@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Inter', sans-serif;
}

.navbar-fixed {
    @apply fixed z-[9999] bg-dark bg-opacity-60;
    backdrop-filter: blur(5px);
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2);
}

.hamburger-line {
    @apply w-[30px] h-[2px] my-2 block bg-white
}

.hamburger-active > span:nth-child(1) {
    @apply rotate-45
}

.hamburger-active > span:nth-child(2) {
    @apply scale-0
}

.hamburger-active > span:nth-child(3) {
    @apply -rotate-45
}

